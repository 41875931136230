import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { CameraIcon } from "@heroicons/react/solid"
import Markdown from "react-markdown"

class PageTemplate extends React.Component {
  render() {
    const page = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <>
        <Layout location={this.props.location} title={siteTitle}>
          <SEO
            title={page.frontmatter.title}
            description={page.frontmatter.description || page.excerpt}
          />
          <div className="bg-white overflow-hidden">
            <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
              <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                <div>
                  <h2 className="text-base text-gray-800 font-semibold tracking-wide uppercase">
                    Taxi Hans
                  </h2>
                  <h3 className="font-ibm font-bold mt-2 text-3xl leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    {page.frontmatter.title}
                  </h3>
                </div>
              </div>
              <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="relative lg:row-start-1 lg:col-start-2">
                  <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                    <figure>
                      <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                        <img
                          className="rounded-lg shadow-lg object-cover object-center"
                          src={
                            page.frontmatter.coverImage.childImageSharp.fluid
                              .src
                          }
                          alt="Whitney leaning against a railing on a downtown street"
                          width={1184}
                          height={1376}
                        />
                      </div>
                      <figcaption className="mt-3 flex text-sm text-gray-500">
                        <CameraIcon
                          className="flex-none w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-2">
                          {page.frontmatter.photograph}
                        </span>
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0">
                  <div className="text-base max-w-prose mx-auto lg:max-w-none">
                    <p className="text-lg text-gray-500">
                      {page.frontmatter.description}
                    </p>
                  </div>
                  <div className="mt-5 prose prose-yellow text-gray-800 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                    <div dangerouslySetInnerHTML={{ __html: page.html }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        description
        photograph
        coverImage {
          id
          childImageSharp {
            gatsbyImageData(
              jpgOptions: { progressive: true }
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
